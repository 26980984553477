import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { URL_SERVICES } from "../../config/config";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user;
  public token;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    public router: Router
  ) {
    this.loadStorage();
  }

  // Load user information in localstorage
  loadStorage() {
    if (localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
      this.user = JSON.parse(localStorage.getItem("user"));
    } else {
      this.token = "";
      this.user = null;
    }
  }
  // Create company
  createCompany(company, role) {
    const url = URL_SERVICES + "users";

    company.role = role;

    return this.http.post(url, company);
  }

  isLoginCompany() {
    this.loadStorage();
    return this.token.length > 5 ? true : false;
  }

  isLoginClient() {
    this.loadStorage();
    return this.token.length > 5 ? true : false;
  }

  loadUser() {
    let url = URL_SERVICES + "perfil";

    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + this.token }),
    };

    return this.http.get(url, headers);
  }

  getUser() {
    if (!this.user) {
      this.router.navigate(["/cuenta/login"]);
    }
    let url = URL_SERVICES + "perfil";

    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + this.token }),
    };

    return this.http.get(url, headers);
  }

  sendAddress(data) {
    const url = URL_SERVICES + "change-address/" + this.user.id;

    return this.http.post(url, data);
  }

  isLogin() {
    this.loadStorage();
    return this.token.length > 5 ? true : false;
  }

  // login
  login(user) {
    const url = URL_SERVICES + "ingresar";

    return this.http.post(url, user).subscribe(
      (resp: any) => {
        console.log(resp);
        const response = resp;
        // if (resp.user.role === "Cliente") {
        this.saveStorage(response.user.id, response.token, response.user);
        this.user = response.user;
        // this.cart.load()
        this.openModal();
        this.router.navigate(["/home"]);
        return true;
        // } else {
        //   this.toastr.error(`No posee permisos para ingresar aqui`);
        //   return;
        // }
        // if( response.user.role === 'Empresa') {
        //   this.router.navigate(['/cuenta/dashboard']);
        //   return true;
        // }else if( response.user.role === 'Cliente') {
        //   this.router.navigate(['/cuenta/dashboard']);
        //   return true;
        // }
      },
      (error: any) => {
        this.toastr.error(`Correo o contraseña invalida`);
      }
    );
  }

  openModal() {
    // this.bsModalRef = this.modalService.show(ModalCheckoutComponent, Object.assign({}, {class:'modal-lg'}, {
    //     keyboard: false, ignoreBackdropClick: true
    //   }));
    // // this.sharedService.modalPrincipal = false;
    // this.bsModalRef.content.closeBtnName = 'Cerrar';
    // this.bsModalRef.content.continueBtnName = 'Seguir Comprando';
  }

  // Logout
  logout() {
    this.user = null;
    this.token = "";

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("home-modal");

    const url = URL_SERVICES + "logout";
    this.http.get(url);
    this.toastr.success(`Se cerró la sesión`);
    this.router.navigate(["/login"]);
  }

  // Save user information in localstorage
  saveStorage(id, token: string, user) {
    localStorage.setItem("id", id);
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    this.token = token;
  }

  getAddressSucursal() {
    if (!this.user) {
      this.router.navigate(["/cuenta/login"]);
    }
    let url = URL_SERVICES + "get-address-sucursal/" + this.user.sucursal;
    // url += '?token=' + this.token;

    return this.http.get(url);
  }

  updateUser(data) {
    let url = URL_SERVICES + "editarperfil";

    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + this.token }),
    };

    return this.http.post(url, data, headers).subscribe(
      (response: any) => {
        console.log(response);
        this.saveStorage(response.user.id, this.token, response.user);
        this.toastr.success("El usuario se actualizo correctamente");
        // this.user = response.user;
        return response;
      },
      (error) => {
        this.toastr.error(error.error.error);
      }
    );
  }

  updatePassword(data) {
    let url = URL_SERVICES + "cambiar-pass";

    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + this.token }),
    };

    return this.http.post(url, data, headers).subscribe(
      (response: any) => {
        console.log(response);
        // this.saveStorage(response.user.id, this.token, response.user);
        this.toastr.success("La contraseña se actualizo correctamente");
        // this.user = response.user;
        return response;
      },
      (error) => {
        this.toastr.error(error.error.error);
      }
    );
  }

  // Save image profile
  saveImageProfile(formData) {
    let url = URL_SERVICES + "users/picture-profile/" + this.user.id;
    url += "?token=" + this.token;

    return this.http.post(url, formData).subscribe(
      (resp: any) => {
        const response = resp;
        this.toastr.success(`La imagen se actualizo correctamente`);
        this.saveStorage(response.id, this.token, response);
      },
      (error) => {
        this.toastr.error(`Error subiendo imagen`);
      }
    );
  }

  recoverPass(user) {
    const url = URL_SERVICES + "reset-password";
    // url += '?token=' + this.token;

    return this.http.post(url, user).subscribe(
      (resp: any) => {
        this.toastr.success(
          `Se ha enviado un correo a su bandeja de entrada para restablecer su contraseña`
        );
      },
      (error) => {
        this.toastr.error(`Error recuperando contraseña`);
      }
    );
  }

  resetPassword(formReset) {
    const url = URL_SERVICES + "password/reset";

    return this.http.post(url, formReset).subscribe(
      (resp: any) => {
        this.toastr.success(`La contraseña se restauró con exito`);
      },
      (error) => {
        this.toastr.error(`No se ha podido restaurar la contraseña`);
      }
    );
  }
}
