import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  HostBinding,
  ElementRef,
  ViewChild,
  Inject,
  NgZone,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Product } from "../../classes/product";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  Observable,
  Subject,
  async,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from "rxjs";
import { ProductService } from "../../services/product.service";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
export type SearchLocation = "header" | "indicator" | "mobile-header";

@Component({
  selector: "app-header-four",
  templateUrl: "./header-four.component.html",
  styleUrls: ["./header-four.component.scss"],
})
export class HeaderFourComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @Output() setproductNameEvent = new EventEmitter<{ name: string }>();
  suggestedProducts: Product[] | any[] = [];
  keyword = "";
  hasSuggestions = false;
  form: FormGroup;

  showSearches: boolean = false;
  isSearching: boolean = false;
  searchedProducts: any = [];
  allProducts: any = [];
  valueInput = "";

  @Input() class: string = "header-2 header-6";
  @Input() themeLogo: string = "assets/images/logos/logo_headerecoo.png"; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Output() escape: EventEmitter<void> = new EventEmitter<void>();
  @Input() location: SearchLocation;
  @Output() closeButtonClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("productSearchInput") productInput: ElementRef;
  @ViewChild("listGroup") listGroup: ElementRef;

  public stick: boolean = false;
  public stickClass: string = '';
  public stickMenuClass: string = '';
  public hidden: string = '';
  public justifyContent: string = 'space-between';

  constructor(
    private _router: Router,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private _productsService: ProductService
  ) {}

  
  // @HostListener("window:scroll", ["$event"])
  // onWindowScroll(event) {
  //   console.log(event);
  // //   if ($(this).scrollTop() > 150) {
  // //     menu.addClass('fixed');
  // // } else {
  // //     menu.removeClass('fixed');
  // // }
  // }

  @HostListener("window:keydown", ["$event"])
  keyDown(event) {
    if (event.keyCode == 27) {
      this.showSearches = false;
      // Obtener todos los elementos
      let items = this.listGroup.nativeElement.querySelectorAll("li");
      let actual = Array.from(items).findIndex((item: any) =>
        item.classList.contains("active")
      );

      items[actual].classList.remove("active");
    }

    if (!this.listGroup) {
      return; // No existe la lista
    }

    // Obtener todos los elementos
    let items = this.listGroup.nativeElement.querySelectorAll("li");
    // Saber si alguno está activo
    let actual = Array.from(items).findIndex((item: any) =>
      item.classList.contains("active")
    );

    // Analizar tecla pulsada
    if (event.keyCode == 13) {
      // Tecla Enter, evitar que se procese el formulario
      event.preventDefault();
      // ¿Hay un elemento activo?
      if (items[actual]) {
        // Hacer clic
        items[actual].click();
      } else {
        this.goToListProducts();
      }
    }
    if (event.keyCode == 38 || event.keyCode == 40) {
      // Flecha arriba (restar) o abajo (sumar)
      if (items[actual]) {
        // Solo si hay un elemento activo, eliminar clase
        items[actual].classList.remove("active");
      }
      // Calcular posición del siguiente
      actual += event.keyCode == 38 ? -1 : 1;
      // Asegurar que está dentro de los límites
      if (actual < 0) {
        actual = -1;
      } else if (actual >= items.length) {
        actual = items.length - 1;
      }

      if (actual >= 0) {
        // Asignar clase activa
        items[actual].classList.add("active");
      }
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.productSearch();
    }, 1000);
  }

  getProductsSeggested(query): Observable<Product[]> {
    this._productsService.query = query;
    return this._productsService.getSuggestions;
  }

  productSearch() {
    // Adding keyup Event Listerner on input field
    const search$ = fromEvent(this.productInput.nativeElement, "keyup").pipe(
      map((event: any) => {
        this._productsService.query = event.target.value;
        return event.target.value;
      }),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (term === "") {
          this.showSearches = false;
        }
        return term ? this.getProductsSeggested(term) : of<any>(term);
      }),
      tap((data) => {
        if (!data.length && data === "") {
          this.showSearches = false;
        } else {
          this.showSearches = true;
        }
        this.isSearching = false;
        this.cdr.markForCheck();
      })
    );

    search$.subscribe((data) => {
      if (!data.length && data === "") {
        data = [];
      }

      this.isSearching = false;
      this.allProducts = data;
      this.searchedProducts = data.slice(0, 5);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeSuggestion();
  }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(query: string) {
    this._productsService.query = query;
    this._productsService.getSuggestions.subscribe((products: Product[]) => {
      this.hasSuggestions = products.length > 0;

      if (products.length > 0) {
        this.suggestedProducts = products;
        this.cdr.markForCheck();
      } else {
        this.showSearches = false;
      }
    });
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  trackById(index, item): void {
    return item._id;
  }

  onFocused(e) {
    // do something when input is focused
  }

  openSuggestion(): void {
    // this.classSearchSuggestionsOpen = true;
  }

  closeSuggestion(): void {
    // this.classSearchSuggestionsOpen = false;
    // this.form.reset();
  }

  addToCart(product: any) {
    this._productsService.addToCart(product);
  }

  goToListProducts() {
    this.valueInput = "";
    this.showSearches = false;
    this.cdr.markForCheck();
    this._router.navigate([`/productos/${this._productsService.query}`]);
  }

  goToProduct(id: number): void {
    this.valueInput = "";
    this.showSearches = false;
    this.cdr.markForCheck();
    this._router.navigate(["/producto", id]);
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stickClass = 'stick';
      this.stickMenuClass = 'stick-menu';
      this.justifyContent = 'justify-center';
      this.hidden = 'd-none';
      this.stick = true;
    } else {
      this.stickClass = '';
      this.stickMenuClass = '';
      this.justifyContent = 'space-between';
      this.hidden = '';
      this.stick = false;
    }
  }
}
