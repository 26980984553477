import {
  Component,
  OnInit,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Observable, of, take } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { UserService } from "../../services/user.service";
import { ToastrService } from "ngx-toastr";
import { NotificationsService } from "../../services/notifications.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public products: Product[] = [];
  public notifications = [];
  public search: boolean = false;
  public userData;
  public user;

  public languages = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "French",
      code: "fr",
    },
  ];

  public currencies = [
    {
      name: "Euro",
      currency: "EUR",
      price: 0.9, // price of euro
    },
    {
      name: "Rupees",
      currency: "INR",
      price: 70.93, // price of inr
    },
    {
      name: "Pound",
      currency: "GBP",
      price: 0.78, // price of euro
    },
    {
      name: "Dollar",
      currency: "USD",
      price: 1, // price of usd
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: Router,
    private translate: TranslateService,
    private toastrService: ToastrService,
    public _userService: UserService,
    public productService: ProductService,
    public notificationsService: NotificationsService
  ) {
    this.userData = this._userService.user;
    this.user = this.userData.data ? this.userData.data : this.userData;
    this.refresh();
    this.notificationsService.getNotifications.subscribe((response) => {
      this.notifications = response.slice(0, 5);
    });
  }

  ngOnInit(): void {}

  refresh() {
    this.productService.cartItems.subscribe((response) => {
      this.productService.items$.subscribe((response) => {
        this.products = response;
      });
    });
  }

  searchToggle() {
    this.search = !this.search;
  }

  readNotification(notification) {
    console.log(notification);
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  // get getTotal(): Observable<number> {
  //   return this.productService.cartTotalAmount();
  // }
  public get getSubTotal(): Observable<number> {
    const total = this.products.map((product: Product) => {
      return this.products.reduce((prev, curr: Product) => {
        let price = curr.product_obj.data_suc.price;
        if (curr.discount) {
          price =
            curr.data_suc.price - (curr.data_suc.price * curr.discount) / 100;
        }
        return prev + price * curr.cant;
      }, 0);
    });

    return of(total[0]);
    // return this.productService.cartTotalAmount();
  }

  public get getIva(): Observable<number> {
    const total = this.products.map((product: Product) => {
      return this.products.reduce((prev, curr: Product) => {
        let iva = curr.product_obj.iva * curr.cant;
        if (curr.discount) {
          iva = curr.iva * curr.cant;
        }
        return prev + iva;
      }, 0);
    });

    return of(total[0]);
    // return this.productService.cartTotalAmount();
  }

  public get getTotal(): Observable<number> {
    const total = this.products.map((product: Product) => {
      return this.products.reduce((prev, curr: Product) => {
        let price = curr.product_obj.data_suc.price + curr.product_obj.iva;
        if (curr.discount) {
          price =
            curr.data_suc.price +
            curr.product_obj.iva -
            (curr.data_suc.price * curr.discount) / 100;
        }
        return prev + price * curr.cant;
      }, 0);
    });

    return total.length > 0 ? of(total[0]) : of(0);
    // return this.productService.cartTotalAmount();
  }

  deleteAllItems() {
    this.productService.removeAllCartItem(this.products).subscribe(() => {
      this.refresh();
    });
  }

  removeItem(product: any) {
    // this.productService.removeCartItem(product, product.id);
    this.productService.removeCartItem(product, product.id).subscribe(() => {
      this.productService.cartItems.subscribe(
        (response) => (this.products = response)
      );
    });
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  goToCart() {
    this.route.navigate(["/cart"]);
  }
}
