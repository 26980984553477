<div class="product-pagination" *ngIf="items.length">
    <div class="theme-paggination-block">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <div class="product-search-count-bottom p-r-0">
                        <nav aria-label="Page navigation">
                            <ul class="pagination" *ngIf="paginate.pages && paginate.pages.length">
                                <li class="page-item" [ngClass]="{disabled:paginate.currentPage === 1}">
                                    <a href="javascript:void(0)" class="page-link" (click)="pageSet(paginate.currentPage - 1)" aria-label="Previous">
                                        <span aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li class="page-item active" *ngFor="let page of paginate.pages" [ngClass]="{ active: current == page }">
                                    <a href="javascript:void(0)" class="page-link" (click)="pageSet(page)">{{ page }}</a>
                                </li>
                                <li class="page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
                                    <a href="javascript:void(0)" class="page-link" (click)="pageSet(paginate.currentPage + 1)" aria-label="Next">
                                        <span aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <!-- <h5>Showing Products 1-{{ products.length }} of {{ paginate.totalItems }} Result</h5> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>