import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    public _userService: UserService,
    public router: Router,
    public _toastr: ToastrService
  ) {}
  canActivate() {
    if (
      this._userService.isLoginClient() ||
      this._userService.isLoginCompany()
    ) {
      return true;
    } else {
      this._toastr.success(`No está autenticado`);
      this.router.navigate(["/login"]);
      return true;
    }
  }
}
