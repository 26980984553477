import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Product } from "../../classes/product";
import { ProductService } from "../../services/product.service";
import { CategoryService } from "../../services/category.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
  @Output() productsData = new EventEmitter<Product[]>();
  public products: Product[] = [];
  public collapse: boolean = true;
  public categories: any[] = [];

  constructor(
    public categoryService: CategoryService,
    public productsService: ProductService
  ) {
    // this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    // Get Categories..
    this.categoryService.getCategories.subscribe((response) => {
      console.log(response);
      // Sorting Filter
      this.categories = response;
    });
  }

  getProductByCategory(category) {
    console.log(category);
    this.productsService.catId = category.id;
    this.productsService.getProductsByCategory.subscribe((products) => {
      console.log(products);
      this.productsData.emit(products);
    });
  }

  get filterbyCategory() {
    this.productsService.getProducts;
    const category = [...new Set(this.products.map((product) => product.type))];
    return category;
  }
}
