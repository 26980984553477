import { Component, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "./shared/services/notifications.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  idx = 0;
  firstInterval;

  notifications = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    translate: TranslateService,
    private notificationsService: NotificationsService
  ) {
    const firstInterval = setInterval(() => {
      clearInterval(firstInterval);
      this.getNotificationsFake();
    }, 120000);

    const secondInterval = setInterval(() => {
      this.notifications = [];
      this.getNotificationsFake();
    }, 600000);

    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("en");
      translate.addLangs(["en", "fr"]);
    }
  }

  getNotificationsFake() {
    this.notificationsService.getNotificationsFake
      .pipe(take(1))
      .subscribe((notifications) => {
        console.log(notifications);
        this.notifications = notifications["data"];
        this.idx = Math.floor(Math.random() * notifications["data"].length);
        setTimeout(() => {
          this.notifications = [];
        }, 5000);
      });
  }

  closeNotification() {
    this.notifications = [];
  }
}
