<div class="icon-nav">
    <ul>
        <div class="d-flex align-items-center">
            <li class="onhover-div mobile-search search-widgets" id="search-widgets">
                <!-- <div (click)="searchToggle()">
                    <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                    <i class="ti-search"></i>
                </div> -->
                <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                    <div>
                        <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                        <div class="overlay-content">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <form>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                            </div>
                                            <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="onhover-div mobile-cart mar-top">
                <div>
                    <div class="d-flex justify-content-center">
                        <a [routerLink]="['/shop/cart']" class="relative">
                            <img src="assets/images/icon/icon_carrito.svg" width="35px" class="img-fluid" alt="">
                            <i class="ti-shopping-cart"></i>
                            <span class="cart_qty_cls">{{ products.length }}</span>
                        </a>
                    </div>
                </div>
                <div class="mt-0">
                    <span class="bold">Total: {{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span>
                </div>
                <ul class="show-div shopping-cart" *ngIf='!products.length'>
                    <h5>Su carrito esta vacio.</h5>
                </ul>
                <ul class="show-div shopping-cart" *ngIf='products.length'>
                    <li>
                        <div class="buttons">
                            <!-- <button class="btn btn-outline-secondary btn-sm rounded-pill" type="button">Seguir comprando</button> -->
                            <button class="btn btn-primary btn-blue border-0 rounded-pill" type="button" (click)="goToCart()">Ver Carro</button>
                            <!-- <a (click)="deleteAllItems()" class="checkout pointer">Vaciar Carro</a> -->
                            <button class="btn btn-red btn-blue border-0 rounded-pill ml-2" type="button" (click)="deleteAllItems()">Vaciar Carro</button>
                        </div>
                    </li>
                    <li *ngFor="let product of products">
                        <div class="media">
                            <a [routerLink]="['/producto/', product?.id]">
                                <img class="me-3" [src]="product?.product_obj.img_main">
                            </a>
                            <div class="media-body">
                                <a [routerLink]="['/producto/', product?.id]">
                                    <h4>{{ product?.product_obj.name }}</h4>
                                </a>
                                <h4>
                                    <span>
                  {{ product?.cant }} x 
                  {{ product?.product_obj?.data_suc?.price * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                </span>
                                </h4>
                            </div>
                        </div>
                        <div class="close-circle" (click)="removeItem(product)">
                            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                        </div>
                    </li>
                    <li>
                        <div class="total">
                            <h5>Subtotal : <span>{{ getSubTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                            <h5>Iva : <span>{{ getIva | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                            <h5>Total : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                        </div>
                    </li>
                    <li>
                        <div class="buttons">
                            <!-- <button class="btn btn-outline-secondary btn-sm rounded-pill" type="button">Seguir comprando</button> -->
                            <button class="btn btn-primary btn-blue border-0 rounded-pill" type="button" (click)="goToCart()">Ver Carro</button>
                            <!-- <a (click)="deleteAllItems()" class="checkout pointer">Vaciar Carro</a> -->
                            <button class="btn btn-red btn-blue border-0 rounded-pill ml-2" type="button" (click)="deleteAllItems()">Vaciar Carro</button>
                        </div>
                    </li>
                </ul>
            </li>
            <li class="onhover-div mobile-setting">
                <div class="mb-4"><img src="assets/images/icon/icon_notificacion.svg" width="25px" class="img-fluid" alt="settong">
                    <i class="ti-bell"></i></div>
                <span class="cart_qty_noti">{{notifications.length}}</span>
                <div class="show-div setting">
                    <h6 *ngIf="notifications.length === 0">No hay notificaciones</h6>
                    <ul *ngIf="notifications.length > 0">
                        <li *ngFor="let noti of notifications">

                            <div>
                                <h4 href="javascript:void(0)" class="text-bold text-blue">{{ noti.data.header }}</h4>
                                <h5 href="javascript:void(0)">{{ noti.data.text }}</h5>
                                <hr class="border">
                            </div>

                            <!-- <div class="pl-4">
                            <img (click)="readNotification(noti)" src="assets/images/icon/trash.svg" width="55px" class="img-fluid" alt="settong">
                            <i class="ti-eye"></i>
                        </div> -->

                        </li>
                    </ul>
                    <!-- <h6>currency</h6>
                <ul class="list-inline">
                    <li *ngFor="let currency of currencies">
                        <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
                    </li>
                </ul> -->
                </div>
            </li>


            <li class="onhover-dropdown mobile-account pe-4">
                <div class="profile-image">
                    <img src="{{ user.avatar }}" alt="" class="img-fluid avatar-img"> {{ user.name }}
                </div>
                <ul class="onhover-show-div">
                    <!-- <li *ngIf="!_userService.isLogin()">
                    <a data-lng="en">
                  Ingresar
                </a>
                </li> -->
                    <li *ngIf="_userService.isLogin()">
                        <a data-lng="es" class="pointer" routerLink="/dashboard">
                            <h5>Mis Pedidos</h5>
                        </a>
                    </li>
                    <hr class="border">
                    <li *ngIf="_userService.isLogin()">
                        <a data-lng="es" class="pointer" routerLink="/dashboard">
                            <h5>Mi Perfil</h5>
                        </a>
                    </li>
                    <hr class="border">
                    <li *ngIf="_userService.isLogin()">
                        <a data-lng="es" class="pointer" (click)="_userService.logout()">
                            <h5>Cerrar Sesión</h5>
                        </a>
                    </li>
                </ul>
            </li>
        </div>
    </ul>
</div>