import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map, startWith } from "rxjs";
import { URL_SERVICES } from "src/app/config/config";
import { UserService } from "./user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const state = {
  checkoutItems: JSON.parse(localStorage["checkoutItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  public Categories;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _userService: UserService
  ) {}

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  // Category
  private get categories(): Observable<any[]> {
    const url = URL_SERVICES + "category-product";
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    console.log(url);
    this.Categories = this.http.get<any[]>(url, headers).pipe(
      map((data) => {
        console.log(data["data"]);
        return data["data"];
      })
    );
    this.Categories.subscribe((next) => {
      console.log(next);
      localStorage["categories"] = JSON.stringify(next);
    });
    return (this.Categories = this.Categories.pipe(
      startWith(JSON.parse(localStorage["categories"] || "[]"))
    ));
  }

  // Get Categories
  public get getCategories(): Observable<any[]> {
    return this.categories;
  }
}
