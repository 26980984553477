import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  AfterViewInit,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";

@Component({
  selector: "app-home-modal",
  templateUrl: "./home-modal.component.html",
  styleUrls: ["./home-modal.component.scss"],
})
export class HomeModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() product: Product;
  @Input() currency: any;

  @ViewChild("homeModal", { static: false }) HomeModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public products: any[] = [];
  public src: string = "../../../../../assets/images/banner/img_popup.png";

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private productService: ProductService
  ) {
    this.productService.getTopPromo.subscribe((data) => {
      console.log(data);
      this.src = data[0].img;
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  async openModal(product) {
    // if (status) {
    //   this.modalOpen = true;
    //   if (isPlatformBrowser(this.platformId)) {
    // For SSR
    console.log(this.src);
    const src = localStorage.getItem("home-modal");
    console.log(src);
    if (src === this.src) {
      return;
    }

    localStorage.setItem("home-modal", this.src);
    this.modalService
      .open(this.HomeModal, {
        size: "lg",
        ariaLabelledBy: "Home-Modal",
        centered: true,
        windowClass: "theme-modal home-modal HomeModal",
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    //   }
    // }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
