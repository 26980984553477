import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, startWith, delay } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { URL_SERVICES } from "../../config/config";
import { UserService } from "./user.service";

const state = {
  products: JSON.parse(localStorage["products"] || "[]"),
  wishlist: JSON.parse(localStorage["wishlistItems"] || "[]"),
  compare: JSON.parse(localStorage["compareItems"] || "[]"),
  cart: JSON.parse(localStorage["cartItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Dollar", currency: "USD", price: 1 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  public product;
  public catId: number = 0;
  public pageNo: number = 1;
  public perPage: number = 30;
  public perPagerSuggestion: number = 5;
  public query: string = "";
  public totalProducts: number;

  constructor(
    private http: HttpClient,
    private _userService: UserService,
    private toastrService: ToastrService
  ) {}

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    const pageNo = this.pageNo;
    const url = URL_SERVICES + `products/${this.perPage}?page=${pageNo}`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    this.Products = this.http.get<Product[]>(url, headers).pipe(
      map((data) => {
        this.totalProducts = data["meta"].total;
        return data["data"];
      })
    );
    this.Products.subscribe((next) => {
      localStorage["products"] = JSON.stringify(next);
    });
    return (this.Products = this.Products.pipe(
      startWith(JSON.parse(localStorage["products"] || "[]"))
    ));
  }

  // Products by category
  private get productsByCategory(): Observable<Product[]> {
    const pageNo = this.pageNo;
    const catId = this.catId;
    const url =
      URL_SERVICES +
      `products-by-cat-id/${this.catId}/${this.perPage}?page=${pageNo}`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    this.Products = this.http.get<Product[]>(url, headers).pipe(
      map((data) => {
        this.totalProducts = data["meta"].total;
        return data["data"];
      })
    );
    this.Products.subscribe((next) => {
      localStorage["products"] = JSON.stringify(next);
    });
    return (this.Products = this.Products.pipe(
      startWith(JSON.parse(localStorage["products"] || "[]"))
    ));
  }

  // Products by Suggestions
  private get getProductsSuggestions(): Observable<Product[]> {
    const pageNo = this.pageNo;
    const catId = this.catId;
    console.log(this.query);
    const url =
      URL_SERVICES +
      `products-search/${this.query}/${this.perPage}?page=${pageNo}`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    this.Products = this.http.get<Product[]>(url, headers).pipe(
      map((data) => {
        console.log(data);
        this.totalProducts = data["meta"].total;
        return data["data"];
      })
    );
    this.Products.subscribe((next) => {
      localStorage["products"] = JSON.stringify(next);
    });
    return (this.Products = this.Products.pipe(
      startWith(JSON.parse(localStorage["products"] || "[]"))
    ));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  public get getProductsByCategory(): Observable<Product[]> {
    return this.productsByCategory;
  }

  public get getSuggestions(): Observable<Product[]> {
    return this.getProductsSuggestions;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    const url = URL_SERVICES + "products/";
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    this.product = this.http.get<Product>(url, headers).pipe(
      map((data) => {
        return data["data"];
      })
    );
    this.product.subscribe((next) => {
      localStorage["product"] = JSON.stringify(next);
    });
    return (this.product = this.product.pipe(
      startWith(JSON.parse(localStorage["product"] || ""))
    ));
    // return this.products.pipe(
    //   map((items) => {
    //     return items.find((item: any) => {
    //       return item.title.replace(" ", "-") === slug;
    //     });
    //   })
    // );
  }

  public getProductById(id: number): Observable<Product> {
    const url = URL_SERVICES + "product/" + id;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.get<Product>(url, headers);
    // this.product.subscribe((next) => {
    //   console.log(next);
    //   localStorage["product"] = JSON.stringify(next);
    // });
    // return (this.product = this.product.pipe(
    //   startWith(JSON.parse(localStorage["product"] || ""))
    // ));
    // return this.products.pipe(
    //   map((items) => {
    //     return items.find((item: any) => {
    //       return item.title.replace(" ", "-") === slug;
    //     });
    //   })
    // );
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const url = URL_SERVICES + `fav-user`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.get<Product[]>(url, headers).pipe(
      map((data) => {
        return data["data"];
      })
    );
    // const itemsStream = new Observable((observer) => {
    //   observer.next(state.wishlist);
    //   observer.complete();
    // });
    // return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlistToDb(productId: number) {
    const url = URL_SERVICES + `fav-user`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.post<Product[]>(url, { product_id: productId }, headers);
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    this.wishlistItems.subscribe((products) => {
      const wishlistItem = products.find(
        (item) => item.product_id === product.id
      );
      if (!wishlistItem) {
        state.wishlist.push({
          ...product,
        });

        this.addToWishlistToDb(product.id).subscribe(() => {
          this.toastrService.success(
            "Producto agregado a la lista de favoritos."
          );
          localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        });
      } else {
        this.toastrService.info("El producto ya se encuentra en favoritos.");
      }
    });
    // const wishlistItem = state.wishlist.find((item) => item.id === product.id);
    // if (!wishlistItem) {
    //   state.wishlist.push({
    //     ...product,
    //   });
    // }
    // this.toastrService.success("Producto agregado a la lista de favoritos.");
    // localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    // return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));

    const url = URL_SERVICES + `fav-user/${product.id}`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.delete(url, headers).pipe(
      map((data) => {
        this.toastrService.success(`${data["msg"]}`);
      })
    );
  }

  public removeAllWishlistItem(products: Product[]): any {
    products.forEach((product) => {
      const index = state.wishlist.indexOf(product.product_id);
      state.wishlist.splice(index, 1);
      localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    });

    const url = URL_SERVICES + `fav-user-delete-all`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.delete(url, headers).pipe(
      map((data) => {
        this.toastrService.success(`${data["msg"]}`);
      })
    );
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find((item) => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    this.toastrService.success("Product has been added in compare.");
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  private itemsSubject$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  readonly items$: Observable<Product[]> = this.itemsSubject$.asObservable();
  private onAddingSubject$: Subject<Product> = new Subject();

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const url = URL_SERVICES + `cart-user`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.get<Product[]>(url, headers).pipe(
      map((data) => {
        this.itemsSubject$.next(data["data"]);
        return data["data"];
      })
    );
    // const itemsStream = new Observable((observer) => {
    //   observer.next(state.cart);
    //   observer.complete();
    // });
    // return <Observable<Product[]>>itemsStream;
  }

  // Update to Wishlist
  public updateCartToDb(cartItem) {
    const url = URL_SERVICES + `cart-user/${cartItem.product_id}`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.put<Product[]>(
      url,
      { product_id: cartItem.product_id, cant: cartItem.cant },
      headers
    );
  }

  // Add to Cart
  public addToCartToDb(productId: number, qty: number) {
    const url = URL_SERVICES + `cart-user`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    // console.log(productId);
    // console.log(qty);
    // this.itemsSubject$.next(state.cart);
    return this.http.post<Product[]>(
      url,
      { product_id: productId, cant: qty },
      headers
    );
  }

  // Add to Cart
  public addToCart(product): any {
    // const cartItem = state.cart.find((item) => item.id === product.id);
    const qty = product.cant ? product.cant : 1;
    // const items = cartItem ? cartItem : product;

    this.cartItems.subscribe((products) => {
      const cartItem = products.find((item) => item.product_id === product.id);
      // console.log(cartItem);
      const items = cartItem ? cartItem : product;
      const stock = this.calculateStockCounts(items, qty);

      if (!cartItem) {
        state.cart.push({
          ...product,
          cant: qty,
        });
        // this.itemsSubject$.next(state.cart);
        this.addToCartToDb(product.id, qty).subscribe(() => {
          this.cartItems.subscribe(() => {
            this.toastrService.success("Producto agregado al carrito.");
            localStorage.setItem("cartItems", JSON.stringify(state.cart));
          });
        });
      } else {
        cartItem.cant += qty;
        this.updateCartToDb(cartItem).subscribe(() => {
          this.toastrService.success("Carrito actualizado.");
          localStorage.setItem("cartItems", JSON.stringify(state.cart));
        });
      }
    });

    // if (!stock) return false;

    // if (cartItem) {
    //   //EDITAR
    //   cartItem.quantity += qty;
    // } else {
    //   //CREAR
    //   state.cart.push({
    //     ...product,
    //     quantity: qty,
    //   });
    // }

    this.OpenCart = true; // If we use cart variation modal
    // localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number, input = false) {
    this.cartItems.subscribe((products) => {
      const cartItem = products.find(
        (item) => item.product_id === product.product_id
      );
      const items = cartItem ? cartItem : product;

      if (input) {
        cartItem.cant = quantity;
      } else {
        cartItem.cant += quantity;
      }

      this.updateCartToDb(cartItem).subscribe(() => {
        this.toastrService.success("Carrito actualizado.");
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
      });
    });
    // return state.cart.find((items, index) => {
    //   if (items.id === product.id) {
    //     const qty = state.cart[index].quantity + quantity;
    //     const stock = this.calculateStockCounts(state.cart[index], quantity);
    //     if (qty !== 0 && stock) {
    //       state.cart[index].quantity = qty;
    //     }
    //     localStorage.setItem("cartItems", JSON.stringify(state.cart));
    //     return true;
    //   }
    // });
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < qty || stock == 0) {
      this.toastrService.error(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  // Remove Cart items
  // public removeCartItem(product: Product): any {
  //   const index = state.cart.indexOf(product);
  //   state.cart.splice(index, 1);
  //   localStorage.setItem("cartItems", JSON.stringify(state.cart));
  //   return true;
  // }

  // Remove Wishlist items
  public removeCartItem(product: Product, cartId): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));

    const url = URL_SERVICES + `cart-user/${cartId}`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.delete(url, headers).pipe(
      map((data) => {
        this.toastrService.success(`${data["msg"]}`);
      })
    );
  }

  public removeAllCartItem(products: Product[]): any {
    products.forEach((product) => {
      const index = state.cart.indexOf(product.product_id);
      state.cart.splice(index, 1);
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
    });

    const url = URL_SERVICES + `cart-user-delete-all`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.delete(url, headers).pipe(
      map((data) => {
        this.toastrService.success(`${data["msg"]}`);
      })
    );
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.product_obj.data_suc.price;
          if (curr.discount) {
            price =
              curr.data_suc.price - (curr.data_suc.price * curr.discount) / 100;
          }
          return (prev + price * curr.cant) * this.Currency.price;
        }, 0);
      })
    );
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(
      map((product) =>
        product.filter((item: Product) => {
          if (!filter.length) return true;
          const Tags = filter.some((prev) => {
            // Match Tags
            if (item.tags) {
              if (item.tags.includes(prev)) {
                return prev;
              }
            }
          });
          return Tags;
        })
      )
    );
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        } else if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    }
  }

  // Get Cart Items
  public get topPromo(): Observable<Product[]> {
    const url = URL_SERVICES + `modal`;
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.get<any>(url, headers).pipe(
      map((data) => {
        return data["data"];
      })
    );
    // const itemsStream = new Observable((observer) => {
    //   observer.next(state.cart);
    //   observer.complete();
    // });
    // return <Observable<Product[]>>itemsStream;
  }

  public get getTopPromo(): Observable<any> {
    return this.topPromo;
  }
  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number = this.totalProducts,
    currentPage: number = 1,
    pageSize: number = 30
  ) {
    // calculate total pages
    let totalPages = Math.ceil(this.totalProducts / pageSize);

    // Paginate Range
    let paginateRange = 10;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, this.totalProducts - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: this.totalProducts,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
