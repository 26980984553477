<!-- header start -->
<header [ngClass]="class" [ngClass]="stickClass">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li>Welcome to Our store Multikart</li>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-end">
                    <ul class="header-dropdown">
                        <li class="compare">
                            <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
                        </li>
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
                        </li>
                        <li class="onhover-dropdown mobile-account">
                            <i class="fa fa-user" aria-hidden="true"></i> My Account
                            <ul class="onhover-show-div">
                                <li>
                                    <a data-lng="en">
                        Login
                      </a>
                                </li>
                                <li>
                                    <a data-lng="es">
                        Logout
                      </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="px-2" >
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu border-section border-top-0" >
                    <div class="brand-logo layout2-logo" >
                        <a [routerLink]="['/home']">
                            <img [src]="themeLogo" class="img-fluid" alt="logo">
                        </a>
                    </div>
                    <div class="search search--location--header search--suggestions-open search--has-suggestions">
                        <div class="search__body relative">
                            <div class="form_search">
                                <input id="search" #productSearchInput [(ngModel)]="valueInput" class="nav-search nav-search-field" aria-expanded="true" placeholder="Buscar productos..." type="text" autocomplete="off">
                            </div>
                            <div class="card absolute w-100" [hidden]="!showSearches">
                                <div class="search search-product">
                                    <form>
                                        <ul class="list-group" #listGroup>
                                            <li *ngFor="let product of searchedProducts; trackBy: trackById" class="search-result px-2" (click)="goToProduct(product.id)">
                                                <div class="row pb-2 pt-3">
                                                    <div class="col-2 align-items-center"><img src="{{product.img_main}}" width="50" alt=""></div>
                                                    <div class="col-6 align-items-center">
                                                        <a [routerLink]="['/producto/', product?.id]">{{ product.name }}</a>
                                                        <div>
                                                            <small>SKU: {{ product.sku }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 align-items-center">${{ product.data_suc?.price| number:'1.2-2' }}</div>
                                                    <div class="col-2 align-items-center">
                                                        <button class="btn btn-primary fab-carr" (click)="addToCart(product)">
                                                            <img src="assets/images/icon/icon_carrito_blanco.svg" width="25px" class="img-fluid" alt="">
                                                          </button>
                                                    </div>
                                                </div>
                                            </li>
                                            <li (click)="goToListProducts()">
                                                <div class="search-result" *ngIf="searchedProducts.length > 0">
                                                    <div class="row py-2">
                                                        <div class="col-12 px-3 py-2">
                                                            <button class="btn btn-primary w-100" (click)="goToListProducts()">
                                                                VER TODOS ({{allProducts.length}})
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="searchedProducts && searchedProducts.length <= 0 && !isSearching">
                                    <img width="200" src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                    <h3>¡Lo sentimos! no pudimos encontrar ningún resultado para tu búsqueda </h3>
                                    <!-- <p>Please check if you have misspelt something or try searching with other words.</p> -->
                                    <!-- <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a> -->
                                </div>
                                <!-- <p class="search" *ngIf="searchedProducts && searchedProducts.length <= 0 && !isSearching">No se encontraron productos</p>
                                <p class="search" [hidden]="!isSearching">Buscando productos...</p> -->
                            </div>
                        </div>
                        <!-- <div class="ng-autocomplete form_search">
                            <ng-autocomplete [data]="suggestedProducts" [searchKeyword]="keyword" placeholder="Buscar Producto" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div> -->
                    </div>
                    <div class="menu-right pull-right">
                        <app-settings></app-settings>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="width-100">
        <div class="row ">
            <div class="col-lg-12">
                <div class="main-nav-center">
                    <app-menu ></app-menu>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header end -->