<div class="p-3 card border-0">
    <div class="img-wrapper">
        <!-- <div class="lable-block">
            <span class="lable3" *ngIf="product.new">new</span>
            <span class="lable4" *ngIf="product.sale">on sale</span>
        </div> -->
        <!-- name.replace(' ', '-') -->
        <div class="front">
            <a [routerLink]="['/producto/', product?.id]">
                <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product?.img_main" class="thumb img-fluid lazy-loading" alt="{{ product?.name }}" />
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a [routerLink]="['/producto/', product?.id]">
                <img [src]="ImageSrc ? ImageSrc : product?.imgs_gallery[1]" class="img-fluid lazy-loading" alt="{{ product?.name }}">
            </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail">
            <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product?.img_main">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
                    <img [lazyLoad]="image.src">
                </a>
            </li>
        </ul>
        <div class="cart-info cart-wrap">
            <!-- <a href="javascript:void(0)" title="Agregar al carro" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a> -->
            <a href="javascript:void(0)" title="Agregar al carro" (click)="addToCart(product)" *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Favoritos" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a> -->
        </div>
    </div>
    <div class="product-detail">
        <div>
            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
            <a [routerLink]="['/producto/', product?.id]">
                <h4 class="text-blue pt-3 text-center">{{ product?.name | titlecase }}</h4>
            </a>
            <p>{{ product?.description }}</p>
            <div class="d-flex justify-content-between align-items-center my-3">
                <div [class.icon-disponible]="product.status === 1" [class.icon-nodisponible]="product.status !== 1"></div>
                <div>
                    {{ product?.status === 1 ? 'Disponible' : 'No Disponible' }}
                </div>
                <div class="ml-3">Cod: {{ product?.sku }}</div>
            </div>
            <!-- <h3 class="text-red pt-3">
                {{ product?.data_suc.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
            </h3> -->
            <h3 class="text-red text-center">
                {{ product?.data_suc?.price | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.discount"><span class="money"> {{ product?.data_suc.price }}</span></del>
            </h3>
            <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->
            <div class="row mt-2">
                <div class="col-12">
                    <div class="qty-box justify-content-end">
                        <div class="input-group">
                            <span class="input-group-prepend">
                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                            <i class="ti-angle-left"></i>
                        </button>
                    </span>
                            <input type="text" name="quantity" class="form-control input-number" (keydown.enter)="addToCart(product)" (focus)="focusInput($event)" (blur)="changeQuantity(product)" [(ngModel)]="counter" />
                            <span class="input-group-prepend">
                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                            <i class="ti-angle-right"></i>
                        </button>
                    </span>
                            <a class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">Agregar</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button class="btn btn-primary fab-carr" (click)="CartModal.openModal(product)">
              <img src="assets/images/icon/icon_carrito_blanco.svg" width="25px" class="img-fluid" alt="">
            </button> -->

        </div>
    </div>
</div>
<!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box> -->
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>