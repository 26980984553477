import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map, startWith } from "rxjs";
import { URL_SERVICES } from "src/app/config/config";
import { UserService } from "./user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const state = {
  checkoutItems: JSON.parse(localStorage["checkoutItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public Notifications;
  public NotificationsFake;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _userService: UserService
  ) {}

  // Notifications
  private get notifications(): Observable<any[]> {
    const url = URL_SERVICES + "noti-unread";
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    console.log(url);
    this.Notifications = this.http.get<any[]>(url, headers).pipe(
      map((data) => {
        console.log(data);
        return data;
      })
    );
    this.Notifications.subscribe((next) => {
      console.log(next);
      localStorage["notifications"] = JSON.stringify(next);
    });
    return (this.Notifications = this.Notifications.pipe(
      startWith(JSON.parse(localStorage["notifications"] || "[]"))
    ));
  }

  private get notificationsFake(): Observable<any[]> {
    const url = URL_SERVICES + "fake-noti";
    const token = this._userService.token;
    const headers = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    console.log(url);
    this.NotificationsFake = this.http.get<any[]>(url, headers).pipe(
      map((data) => {
        console.log(data);
        return data;
      })
    );
    this.NotificationsFake.subscribe((next) => {
      console.log(next);
      localStorage["notificationsFake"] = JSON.stringify(next);
    });
    return (this.NotificationsFake = this.NotificationsFake.pipe(
      startWith(JSON.parse(localStorage["notificationsFake"] || "[]"))
    ));
  }

  // Get Notifications
  public get getNotifications(): Observable<any[]> {
    return this.notifications;
  }

  public get getNotificationsFake(): Observable<any[]> {
    return this.notificationsFake;
  }
}
