<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<div class="cookie-bar left-bottom show" *ngIf="notifications.length">
    <div class="row">
        <div class="col-4 d-flex align-items-center">
            <img width="100" class="border rounded" [src]="notifications[idx].img_product">
        </div>
        <div class="col-8">
            <!-- <img src="../assets/svg/cookie.svg" class="img-fluid" alt="cookie"> -->
            <a href="javascript:void(0)" class="text-center">
                <i class="fa fa-bell " aria-hidden="true"></i>
            </a>
            <p class="semi-bold mb-0">{{ notifications[idx].user_name }} compró un</p>
            <p class="bold ">{{ notifications[idx].produc_name }}</p>
            <p class="semi-bold ">Hace 4 minutos desde CDMX, México</p>
            <!-- <a href="javascript:void(0)" class="btn btn-solid btn-xs">accept and close</a> -->
            <a href="javascript:void(0)" (click)="closeNotification()" class="btn-close "></a>
        </div>
    </div>

</div>
<app-tap-to-top></app-tap-to-top>
<!-- <app-layout-box></app-layout-box> -->