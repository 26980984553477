import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pagination-order",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationAllComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() paginate: any = {};

  current = 1;

  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  pageSet(page: number) {
    this.current = page;
    console.log(this.current);
    console.log(page);
    this.pageChange.emit(page); // Set Page Number
  }
}
