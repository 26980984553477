import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Observable } from "rxjs";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";

@Component({
  selector: "app-cart-variation",
  templateUrl: "./cart-variation.component.html",
  styleUrls: ["./cart-variation.component.scss"],
})
export class CartVariationComponent implements OnInit, OnDestroy {
  @Input() direction: string = "right"; // Default Direction Right

  public products: Product[] = [];

  constructor(public productService: ProductService) {
    this.productService.cartItems.subscribe((response) => {
      this.productService.items$.subscribe((response) => {
        console.log(response);
        this.products = response;
      });
      // console.log(response);
      // this.products = response;
    });
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    // this.productService.removeCartItem(product, product.id);
    this.productService.removeCartItem(product, product.id).subscribe(() => {
      this.productService.cartItems.subscribe(
        (response) => (this.products = response)
      );
    });
  }

  ngOnDestroy(): void {
    this.closeCart();
  }
}
